import { Link } from "@reach/router";
import styles from "./toptab.module.css";

export default function TopTab({
  links,
  path,
}: {
  links: { to: string; name: string }[];
  path?: any;
}) {
  const pathname = window.location.pathname.toLowerCase();
  return (
    <div style={{ height: "auto" }}>
      <div className={styles.tab} data-testid="top-tab">
        {links.map(({ to, name }, index) => (
          <Link
            style={
              pathname === to
                ? {
                    background: "#00962C",
                    color: "#fff",
                    boxShadow: "0px 2px 10px #00962C77",
                  }
                : {}
            }
            to={to}
            key={name}
            data-testid={`active-link-${index}`}
          >
            {name}
          </Link>
        ))}
      </div>
    </div>
  );
}
