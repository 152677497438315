import AuthFormShared, {
  AuthButton,
} from "../../components/AuthFormShared/AuthFormShared";
import {
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Select,
  message,
  Upload,
  Button,
} from "antd";
// import { PhoneInput, PhoneInputFormItem } from "@validate-phone/antd-input";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "@reach/router";
import states from "../../data/states";
import SearchPlace from "components/SearchPlace";
import uploadToS3 from "util/uploadToS3";
import { ArrowUpOutlined, RollbackOutlined } from "@ant-design/icons";
import styles from "./signup.module.css";
import { parse } from "fast-qs";
import axiosConfig from "config/axios";
import PageSpinner from "components/PageSpinner";
// import DeviceWidth from "context/MobileResizeContext";
import React from "react";
const { Option } = Select;

export default function SignUp(props: ReachPathProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [verified, setVerified] = useState<boolean>();
  const [hasRegistered, setHasRegistered] = useState<boolean>();
  const [localGovernment, setLocalGovernmanet] = useState<any>([]);
  const [selectedLocalGovernment, setSelectedLocalGovernmanet] = useState<any>(
    []
  );

  const [stateSearchText, setStateSearchText] = useState<string>("");
  const [
    selectedLocalGovernmentSearchText,
    setSelectedLocalGovernmentSearchText,
  ] = useState<string>("");

  const filteredSelectedLocalGovernments = useMemo(() => {
    if (!selectedLocalGovernmentSearchText) {
      // If the search text is empty, return all services
      return selectedLocalGovernment;
    }
    const lowerCaseSelectedLocalGovernmentSearchText =
      selectedLocalGovernmentSearchText.toLowerCase();
    return selectedLocalGovernment.filter((lga: any) =>
      lga.toLowerCase().includes(lowerCaseSelectedLocalGovernmentSearchText)
    );
  }, [selectedLocalGovernmentSearchText, selectedLocalGovernment]);

  const filteredStates = useMemo(() => {
    if (!stateSearchText) {
      // If the search text is empty, return all services
      return states;
    }
    const lowerCasestateSearchText = stateSearchText.toLowerCase();
    return states.filter((state) =>
      state.toLowerCase().includes(lowerCasestateSearchText)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSearchText, selectedLocalGovernment]);

  // async function submitOTP(values: { otpCode: string }) {
  //   if (isLoading) return;
  //   setIsLoading(true);

  //   try {
  //     const {
  //       data: { data },
  //     } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/supplier/confirm`, {
  //       adminEmail: form.getFieldValue("adminEmail"),
  //       otpCode: String(values.otpCode),
  //     });
  //     message.success("OTP validated successfully");
  //     const { supplier, user, token } = data;
  //     logIn(user, supplier, token);
  //   } catch (error: any) {
  //     if (error.response) {
  //       const data = error.response.data;
  //       if (data.error) message.error(data.error);
  //       data.errors?.forEach((error: any) => message.error(error.msg));
  //     } else message.error("Error logging in");
  //     setIsLoading(false);
  //   }
  // }

  const { search } = useLocation();

  const { email, token, businessName, phoneNumber, firstName, lastName } =
    parse(search) as {
      email: string;
      token: string;
      businessName: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
    };

  async function handleSubmit(values: any) {
    const { confirm, ...otherValues } = values;
    if (isLoading) return;
    setIsLoading(true);
    try {
      await axiosConfig.post(
        `${process.env.REACT_APP_BACKEND_URL}/supplier/register`,
        {
          ...otherValues,
          businessLogo:
            values.businessLogo ??
            "https://materialspro-media.s3.us-west-1.amazonaws.com/user-avatar.png",
        }
      );
      // const { supplier, user, token } = data;
      // logIn(user, supplier, token);
      message.success("Account registered!");
      setIsCompleted(true);
    } catch (error: any) {
      if (error.response) {
        const data = error.response.data;
        if (data.error) message.error(data.error);
        data.errors?.forEach((error: any) => message.error(error.msg));
      } else message.error("Error registering supplier");
    } finally {
      setIsLoading(false);
    }
  }

  const getLGA = async () => {
    await axiosConfig
      .get("nigerianstate")
      .then((res) => {
        setLocalGovernmanet(res.data.data);
      })
      .catch((error) => {});
  };

  // This attempts to check if the user is accidentally hitting this link after a successful registration to print the appropriate error message
  const getRegisteredUser = async () => {
    await axiosConfig
      .get(`admin/reg-uncomplete?email=${email}&accountType=supplier`)
      .then((res) => {
        setHasRegistered(true);
      })
      .catch((error) => {
        setHasRegistered(false);
      });
  };

  useEffect(() => {
    getLGA();
    getRegisteredUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axiosConfig
      .post(`${process.env.REACT_APP_BACKEND_URL}/verifytoken`, {
        email,
        token,
      })
      .then((data) => {
        setVerified(true);
      })
      .catch((error) => {
        message.error("Token not verified!");
        setVerified(false);
        setIsCompleted(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any) => {
    const ordered = localGovernment.filter((lga: any) => e === lga.state)[0]
      .Lgas;
    setSelectedLocalGovernmanet(ordered);
    setStateSearchText("");
  };
  // const isMobile = useContext(DeviceWidth);

  // if (user) return <Redirect from="/" to="/suppliers/dashboard" noThrow />;
  // if (stage === "otp") {
  //   return (
  //     <AuthFormShared title="Email verification" {...props}>
  //       <Form form={otpForm} onFinish={submitOTP} layout="vertical">
  //         <Form.Item
  //           name="otpCode"
  //           label="Input the OTP code"
  //           rules={[{ required: true, message: "Please enter the OTP code" }]}>
  //           <InputNumber className="w-100" />
  //         </Form.Item>
  //         <AuthButton isLoading={isLoading}>Confirm OTP</AuthButton>
  //       </Form>
  //     </AuthFormShared>
  //   );
  // }

  if (verified === undefined) {
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <PageSpinner fullScreen />
      </div>
    );
  }
  if (isCompleted) {
    if (!verified && !hasRegistered) {
      return (
        <div className={styles["confirm-email-wrapper"]}>
          <div className={styles["email-confirm-variant"]}>
            <div className={styles["email-circle"]} />
            <div className={styles["email-circle2"]} />

            <img src="/logo.png" alt="email-confirm" width={150} />
            <p>
              <span>
                {/* <h2>Hello</h2> */}
                You seem to have landed on an expired link, <br />
                Kindly Click on the button below to proceed to the website
              </span>
            </p>
            <div>
              <Button
                icon={<RollbackOutlined />}
                size="large"
                type="primary"
                className={styles.blink}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFF",
                }}
                href={`${process.env.REACT_APP_HOME_URL}/signup?orgType=builder`}
              >
                Click here
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (!verified && hasRegistered) {
      return (
        <div className={styles["confirm-email-wrapper"]}>
          <div className={styles["email-confirm-variant"]}>
            <div className={styles["email-circle"]} />
            <div className={styles["email-circle2"]} />

            {/* <img src="/email-confirm2.png" alt="email-confirm" width={250} /> */}
            <p>
              <h2> Hello!</h2>
              <span>
                {" "}
                Your registration was successful.
                <br />
                Our relationship manager will get in touch with you within 24
                hours!
              </span>
            </p>
            <div>
              <Button
                icon={<RollbackOutlined />}
                size="large"
                type="primary"
                className={styles.blink}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                }}
                href={`${process.env.REACT_APP_BLOG_URL}`}
              >
                Visit Blog
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <div className={styles["email-confirm-variant"]}>
          <div className={styles["email-circle"]} />
          <div className={styles["email-circle2"]} />

          <img
            src="https://materialspro-media.s3.us-west-1.amazonaws.com/email-confirm2.png"
            alt="email-confirm"
            width={250}
          />
          <p>
            Account created successfully!
            <span>
              Thank you for completing your registration, our relationship
              manager would get in touch with you within 24 hours.
            </span>
          </p>
          <div>
            <Button
              icon={<RollbackOutlined />}
              size="large"
              type="primary"
              className={styles.blink}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
              }}
              href={`${process.env.REACT_APP_BLOG_URL}`}
            >
              Visit Blog
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <AuthFormShared title="Register As a supplier" {...props}>
        <div className="w-100">
          <Form
            scrollToFirstError
            // eslint-disable-next-line no-template-curly-in-string
            validateMessages={{ required: "'${label}' is required!" }}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
          >
            <Form.Item
              name="businessName"
              label="Business Name"
              rules={[{ required: true }]}
              initialValue={businessName}
            >
              <Input />
            </Form.Item>

            <Row gutter={10}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="adminFirstName"
                  label="Admin's First Name"
                  rules={[{ required: true }]}
                  initialValue={firstName}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="adminLastName"
                  label="Admin's Last Name"
                  rules={[{ required: true }]}
                  initialValue={lastName}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} md={12}>
                {/* <PhoneInputFormItem
                name="adminPhoneNumber"
                label="Phone number"
                errorMessage="Please provide a valid phone number"
              >
                <PhoneInput />
              </PhoneInputFormItem> */}
                <Form.Item
                  name="adminPhoneNumber"
                  label="Phone Number"
                  rules={[
                    { required: true },
                    {
                      pattern: /^(\+?234?)?0?(7|8|9)(0|1)\d{8}$/,
                      message: "Please provide a valid phone number.",
                    },
                  ]}
                  initialValue={phoneNumber}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="adminEmail"
                  label="Email Address"
                  initialValue={email}
                  rules={[
                    { required: true },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>

            <SearchPlace
              form={form}
              name="businessAddress"
              label="Business Address"
              secondaryName="secondaryBusinessAddress"
              rules={[{ required: true }]}
              placeName="businessAddressPlaceId"
            />
            <Row gutter={10}>
              <Col xs={24} md={12}>
                <Form.Item
                  // rules={[{ required: false, message: "Please Upload an image" }]}
                  name="businessLogo"
                  label="Upload Business Logo"
                  valuePropName="file"
                  getValueFromEvent={(e) => {
                    const file = e.fileList[0];
                    if (file?.status === "done") {
                      return file.response;
                    }
                  }}
                >
                  <Upload
                    customRequest={uploadToS3}
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    accept="image/*"
                  >
                    <Button icon={<ArrowUpOutlined />}>upload an image</Button>
                    <br />
                    <small>
                      Image should not be greater than <b>20MB</b>
                    </small>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  rules={[
                    { required: true, message: "Please upload CAC Document" },
                  ]}
                  name="document"
                  label="Upload CAC Document"
                  valuePropName="file"
                  getValueFromEvent={(e) => {
                    const file = e.fileList[0];
                    if (file?.status === "done") {
                      return file.response;
                    }
                  }}
                >
                  <Upload
                    customRequest={uploadToS3}
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    accept="image/*,.pdf"
                  >
                    <Button icon={<ArrowUpOutlined />}>
                      upload CAC document
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              rules={[{ required: true, message: "Please enter a Tax ID" }]}
              name="taxId"
              label="Enter Tax ID"
            >
              <Input />
            </Form.Item>

            <Row gutter={10}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true }]}
                >
                  <Select
                    dropdownAlign={{
                      overflow: { adjustX: false, adjustY: false },
                    }}
                    // placement="bottomRight"
                    onChange={(e) => handleChange(e)}
                    showSearch
                    onSearch={(value: string) => {
                      setStateSearchText(value);
                    }}
                  >
                    {filteredStates.map((state) => (
                      <Option
                        value={
                          state === "FCT Abuja"
                            ? "Federal Capital Territory"
                            : state
                        }
                        key={state}
                      >
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  name="localGovernment"
                  label="LGA"
                  rules={[{ required: true }]}
                >
                  <Select
                    disabled={selectedLocalGovernment.length < 1}
                    showSearch
                    onSearch={(value: string) => {
                      setSelectedLocalGovernmentSearchText(value);
                    }}
                    onChange={(value) => {
                      form.setFieldsValue({ localGovernment: value });
                      setSelectedLocalGovernmentSearchText("");
                    }}
                  >
                    {filteredSelectedLocalGovernments.map((lgas: any) => (
                      <Option value={lgas} key={lgas}>
                        {lgas}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Col xs={24} md={12}>
              <Form.Item
                name="zipCode"
                label="Zip Code"
                rules={[{ type: "integer" }]}
              >
                <InputNumber minLength={3} className="w-100" maxLength={6} />
              </Form.Item>
            </Col>

            <Form.Item
              label="Password"
              name="adminPassword"
              hasFeedback
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 6 },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["adminPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("adminPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <AuthButton isLoading={isLoading}>Register</AuthButton>
          </Form>
          <p className="text-center">
            Already have an account? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </AuthFormShared>
    </div>
  );
}
