import { navigate, Router } from "@reach/router";
import { Table, Typography, Input, Tooltip, Statistic } from "antd";
import DashboardShared from "../../../components/DashboardShared/DashboardShared";
import TopTab from "../../../components/TopTab/TopTab";
import { SearchOutlined } from "@ant-design/icons";
// import DashboardShared from "components/DashboardShared/DashboardShared";
import DeviceWidth from "../../../context/MobileResizeContext";
import React, { useContext, useEffect, useState, lazy, Suspense } from "react";
// import History from "./history";
import TransactionDetail from "./transaction-details";
import styles from "./transactions.module.css";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import dayjs from "dayjs";
// import InvoiceRef from "./_invoice";
import useSearch from "hooks/useSearch";
import PageSpinner from "components/PageSpinner";
import requestErrorHandler from "util/requestErrorHandler";

const InvoiceRef = lazy(() => import("./_invoice"));
const History = lazy(() => import("./history"));
// import InvoiceRef from "./_invoice";

const { Text } = Typography;
const Pending = () => {
  const isMobile = useContext(DeviceWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const { supplier } = useAuth();

  const { handleSearch, isSearching, searchQuery } = useSearch();
  let searchResult = transactions.filter((transaction) => {
    const homeOwnerName =
      transaction?.homeOwner?.firstName +
      " " +
      transaction?.homeOwner?.lastName;
    return (
      transaction?.frontendId.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      dayjs(transaction?.createdAt)
        .format("MMM DD, YYYY")
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(transaction?.updatedAt)
        .format("MMM DD, YYYY")
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.builderName
        ?.toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      homeOwnerName?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.order?.totalPrice
        .toString()
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      transaction?.trackingStatus
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [transactions, homeownerTransactions] = await Promise.all([
          axios.get(
            `transaction/getallpendingsuppliertransactions/${supplier?.id}`
          ),
          axios.get(
            `homeowner/supplier-pending-orders?supplierId=${supplier?.id}`
          ),
        ]);

        const joinedTransactions = transactions.data.transactions.concat(
          homeownerTransactions.data.transactions
        );
        joinedTransactions.sort((objA: any, objB: any) => {
          return (
            new Date(objB.createdAt).getTime() -
            new Date(objA.createdAt).getTime()
          );
        });
        setTransactions(joinedTransactions);
      } catch (error: any) {
        requestErrorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [supplier?.id]);

  // const paidReder = (text: string | boolean) => {
  //   return (
  //     <>{text ? <Text style={{ color: "#00962C" }}>Paid</Text> : <Text style={{ color: "#FF612F" }}>Pending</Text>}</>
  //   );
  // };
  const webTableColumns = [
    {
      title: "INVOICE ID",
      dataIndex: "frontendId",
      key: "frontendId",
      // render: (text: any) => text.frontendId,
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) =>
        dayjs(text).format("MMM DD, YYYY") as unknown as string,
    },
    {
      title: "NAME",
      dataIndex: "builder",
      key: "builderName",
      ellipsis: true,
      render: (text: Supplier, record: any) => (
        <Tooltip
          title={
            text?.businessName
              ? text.businessName
              : record.homeOwner?.firstName + " " + record.homeOwner?.lastName
          }
          style={{ textTransform: "capitalize" }}
        >
          {text?.businessName
            ? text.businessName
            : record.homeOwner?.firstName + " " + record.homeOwner?.lastName}
        </Tooltip>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (text: any, record: any) => (
        <Tooltip title={text}>
          <Statistic
            valueStyle={{ fontSize: "0.9rem" }}
            prefix="₦"
            value={record?.order?.materialPrice}
          />
          {/* {text.totalPrice} */}
        </Tooltip>
      ),
    },
    // {
    //   title: "PAYMENT STATUS",
    //   dataIndex: "paymentStatus",
    //   key: "paymentStatus",
    //   render: (text: boolean, state: Transaction) =>
    //     paidReder(state.order.isPaidFor),
    // },
    {
      title: "TRACKING STATUS",
      dataIndex: "trackingStatus",
      key: "trackingStatus",
      render: (text: string) => (
        <Text style={{ textTransform: "capitalize" }}>
          {text.replace(/_/g, " ")}
        </Text>
      ),
    },
    // {
    //   title: "TOTAL",
    //   dataIndex: "total",
    //   key: "total",
    //   ellipsis: true,
    //   render: (text: string) => <Tooltip title={text}>{text}</Tooltip>,
    // },
  ];

  const webTableData = [];
  for (let i = 0; i < 100; i++) {
    webTableData.push({
      key: "id",
      "order id": "#34543",
      date: "11 Oct 2019",
      builder: "Global Real Estate LTD",
      supplier: "Chisco Building LTD",
      "payment status": "PAID",
      "delivery status": "PENDING",
      total: "₦90,000,000.00",
    });
  }

  // const mobileTableData = [];
  // for (let i = 0; i < 100; i++) {
  //   mobileTableData.push({
  //     key: "1",
  //     "column-one": ["#2571562", "order date", "11 Oct 2020"],
  //     "column-two": ["Global Real Est.", "payment ststua", "PAID"],
  //     "column-three": ["#90,000,000", "delivery status", "PENDING"],
  //   });
  // }

  return (
    <div>
      <div className={styles.topbar}>
        <Input
          className={styles.search}
          prefix={<SearchOutlined />}
          onChange={(e) => handleSearch(e)}
          placeholder="search"
          size="middle"
          style={{ width: isMobile ? "100%" : "auto", marginRight: "1rem" }}
        />
      </div>
      <div className={styles.table}>
        <Table
          loading={isLoading}
          dataSource={isSearching ? searchResult : transactions}
          columns={webTableColumns}
          rowClassName={styles["table-row"]}
          onRow={(row) => {
            return {
              onClick: (event) => {
                navigate(
                  `transactions/${row.id}?type=${
                    row.homeOwnerId ? "homeowner" : "matpro"
                  }`
                );
              },
            };
          }}
          style={{
            minWidth: "600px",
            textTransform: "capitalize",
          }}
          size="middle"
        />
      </div>
    </div>
  );
};

const MainView = (props: ReachPathProps) => {
  return (
    <div className={styles.container}>
      <TopTab
        links={[
          { to: "", name: "Pending" },
          { to: "history", name: "History" },
        ]}
      />
      <Pending />
    </div>
  );
};

export default function Transactions(props: ReachPathProps) {
  return (
    <DashboardShared title="Transactions">
      <Suspense fallback={<PageSpinner />}>
        <Router>
          <MainView path="/" />
          <TransactionDetail path=":id" />
          <History path="/history" />
          <InvoiceRef path="/invoice/:id" />
        </Router>
      </Suspense>
    </DashboardShared>
  );
}
