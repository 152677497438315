import styles from "./profile.module.css";
import { Button } from "antd";
import { Edit } from "react-feather";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import EditBusinessInfo from "components/EditBusinessInfo";
import { useEffect } from "react";
import { useCallback } from "react";
import axiosConfig from "config/axios";
import PageSpinner from "components/PageSpinner";
import requestErrorHandler from "util/requestErrorHandler";
import React from "react";

export default function Business(props: ReachPathProps) {
  const { supplier: sup } = useAuth() as { supplier: Supplier };
  const [supplier, setSupplier] = useState<Supplier>();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const getSupplier = useCallback(() => {
    axiosConfig
      .get(`supplier/getonesupplier/${sup.organizationId}`)
      .then((res) => {
        setSupplier(res.data.data.supplier[0]);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }, [sup.organizationId]);
  useEffect(() => {
    getSupplier();
  }, [getSupplier]);
  if (!supplier) return <PageSpinner />;
  return (
    <>
      <div className={styles.main}>
        <div className={styles.heading}>
          <Button
            type="link"
            icon={<Edit height={14} />}
            onClick={() => {
              setIsEditOpen(true);
            }}
          >
            Edit Company Profile
          </Button>
        </div>
        <div>
          <div className={styles.table}>
            <table>
              <tr>
                <th>Company name</th>
                <td>{supplier.businessName}</td>
              </tr>
              <tr>
                <th>Company address</th>
                <td>{supplier.businessAddress}</td>
              </tr>
              <tr>
                <th>Zip/Postal code</th>
                <td>{supplier.zipCode}</td>
              </tr>
              <tr>
                <th>State/Country</th>
                <td>{supplier.state}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <EditBusinessInfo
        isEditOpen={isEditOpen}
        onCancel={() => {
          setIsEditOpen(false);
          getSupplier();
        }}
      />
    </>
  );
}
