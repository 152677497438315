import {
  Typography,
  Button,
  Table,
  Space,
  message,
  Skeleton,
  Form,
  Input,
  Popconfirm,
  InputNumber,
  Modal,
  Divider,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./material.module.css";
import { useCallback, useContext, useEffect, useState } from "react";
import DeviceWidth from "../../../context/MobileResizeContext";
import AddVariant from "./Modal/AddVariant";
import axios from "../../../config/axios";
import useAuth from "hooks/useAuth";
import dayjs from "dayjs";
import Spinner from "assets/icons/Spinner";
import { navigate } from "@reach/router";
import requestErrorHandler from "util/requestErrorHandler";

const { Text, Title } = Typography;

interface Item {
  key: string;
  description: string;
  size: string;
  unitPrice: string;
  sku: string;
  total: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber min={0} /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const MaterialDetail = ({
  id,
  path,
  ...props
}: {
  path: string;
  id?: string;
}) => {
  // const variant: [] | any = [];
  const [form] = Form.useForm();
  const isMobile = useContext(DeviceWidth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [inventory, setInventory] = useState<Inventory[]>([]);
  const [material, setMaterial] = useState<{} | any>();
  const { supplier } = useAuth();
  const [isModal, setIsModal] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values: {}) => {
    setIsSubmitting(true);
    axios
      .put("inventory/edit", values)
      .then(() => {
        message.success({
          content: "Inventory Added Successfully",
        });
        setIsModal("");
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => {
        setIsSubmitting(false);
        getMaterial();
      });
  };
  useEffect(() => {
    const tableData: any = inventory.map((item) => {
      return {
        key: item.id,
        description: item.variant.description,
        unitSize: `${item.variant.unitSize} ${item.material.unitOfMeasure}`,
        unitPrice: item.variant.unitPrice,
        sku: item.qtyAvailable,
        // total: item.qtyAvailable * item.variant.unitPrice,
        total: "",
        variantId: item.variant.id,
      };
    });
    setTableData(tableData);
  }, [inventory]);

  const getMaterial = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`material/${id}`);
      setMaterial(data);

      const {
        data: {
          data: { inventory },
        },
      } = await axios.get(`inventory/${supplier?.id}/${id}`);

      setInventory(inventory);
    } catch (error) {
      requestErrorHandler(error);
    }
  }, [id, supplier?.id]);

  useEffect(() => {
    Promise.all([getMaterial()]);
  }, [getMaterial]);

  const columns = [
    {
      title: "VARIANT DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "SIZE",
      dataIndex: "unitSize",
      key: "unitSize",
    },
    {
      title: "UNIT PRICE",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (price: number) => `₦${price.toLocaleString()}`,
    },
    {
      title: "CURRENT INVENTORY",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "",
      dataIndex: "variantId",
      key: "variantId",

      render: (text: string, record: any) => {
        return (
          <Space style={{ display: "flex", justifyContent: "space-between" }}>
            {/* {text} */}
            <Space>
              <Button
                style={{ padding: ".3rem" }}
                type="default"
                onClick={() => {
                  setIsModal(record.key);
                }}
              >
                <EditOutlined />
              </Button>

              <Popconfirm
                title="Sure to delete?"
                onConfirm={async () => {
                  try {
                    // alert(JSON.stringify(record) + "gg");
                    await axios.post("inventory/remove", {
                      supplierId: supplier?.id,
                      variantId: text,
                    });
                    message.success("Variant deleted");
                    getMaterial();
                  } catch (error) {
                    requestErrorHandler(error);
                  }
                }}
                okButtonProps={{
                  danger: true,
                }}
                cancelButtonProps={{ type: "link" }}
              >
                <Button style={{ padding: ".3rem" }} danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={styles["details-container"]}>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        <div>
          <Button
            style={{ padding: 0 }}
            type="text"
            onClick={() => navigate("/suppliers/materials")}
          >
            Material
          </Button>{" "}
          |
          {material && (
            <span
              style={{ textTransform: "capitalize", opacity: ".5" }}
            >{` ${material.brand.brandName} ${material.category.name}
          `}</span>
          )}
          {!material && (
            <span>
              <Skeleton.Input
                style={{ width: "100px", height: "25px", borderRadius: "10px" }}
                active={true}
                size={`large`}
              />
            </span>
          )}
        </div>
      </div>
      <div className={styles["details-card-container"]}>
        {!material &&
          [1, 2, 3].map((i) => (
            <Skeleton.Input
              key={i}
              style={{
                width: `${isMobile ? "100%" : "280px"}`,
                height: "130px",
                borderRadius: "15px",
              }}
              active={true}
              size={`large`}
            />
          ))}
        {material && (
          <>
            <div className={styles["details-card"]}>
              <span>
                Material Brand
                <b>{material.brand.brandName}</b>
              </span>
              <span>
                Material Category
                <b>{material.category.name}</b>
              </span>
              <span>
                Unit of measurement
                <b>{material.unitOfMeasure}</b>
              </span>
            </div>
            <div className={styles["details-card"]}>
              <span>
                <Text>Number of variants</Text>
                <b>{material.variants.length}</b>
              </span>
              <span>
                <Text>Date created</Text>
                <b>{dayjs(material.createdAt).format("DD MMM YYYY")}</b>
              </span>
              {/* <span>
                <Text>Min order qty</Text>
                <b>{`material.variant.minSalesQty`}</b>
              </span> */}
            </div>
            <div className={styles["details-card"]}>
              <img src={material.imageUrl} alt="cement" height="100" />
            </div>
          </>
        )}
      </div>
      <div className={styles["main-body"]}>
        <div className={styles["body-topbar"]}>
          <Title style={{ textTransform: "capitalize", margin: 0 }} level={4}>
            {material && material.brand.brandName}
          </Title>
          <Button
            onClick={() => setShowModal(true)}
            icon={<PlusOutlined />}
            type="primary"
            size="middle"
          >
            Add to inventory
          </Button>
          <AddVariant
            variants={material?.variants}
            materialId={id}
            showModal={showModal}
            setShowModal={setShowModal}
            callback={() => getMaterial()}
          />
        </div>
        <div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              pagination={false}
              columns={columns}
              dataSource={tableData}
              className={styles.table}
              style={{
                fontSize: "0.2rem",
                overflowX: "auto",
                textTransform: "capitalize",
              }}
            />
          </Form>
        </div>
      </div>
      <Modal
        destroyOnClose
        title="Edit Variant"
        visible={!!isModal}
        footer={null}
        onCancel={() => {
          setIsModal("");
        }}
      >
        <Form preserve={false} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            initialValue={inventory.find((i) => i.id === isModal)?.variantId}
            hidden
            label="Variant"
            name="variantId"
            rules={[{ required: true, message: "Please select a variant" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Quantity"
            name="qty"
            rules={[
              { required: true, message: "Please enter a valid quantity" },
              { type: "number", message: "Please enter a valid quantity" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const _inventory = inventory.find(
                    (v) => v.variantId === getFieldValue("variantId")
                  );
                  if (!value && !_inventory?.variant) {
                    return Promise.resolve();
                  }
                  if (!value || _inventory?.variant.minSalesQty! <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Quantity must be greater than or equal to ${_inventory?.variant.minSalesQty}`
                    )
                  );
                },
              }),
            ]}
          >
            <InputNumber className="w-100" />
          </Form.Item>
          <Form.Item
            label="Set Unit Price"
            name="unitPrice"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <InputNumber min={0} className="w-100" />
          </Form.Item>
          <Form.Item hidden initialValue={supplier?.id} name="supplierId">
            <Input />
          </Form.Item>
          <Divider />
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            style={{ marginTop: "-300px", width: "100%" }}
          >
            {isSubmitting ? <Spinner /> : `Edit inventory`}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default MaterialDetail;
