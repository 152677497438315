import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./material.module.css";
import {
  Typography,
  Input,
  Dropdown,
  Button,
  Space,
  Menu,
  Collapse,
  Empty,
} from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import Material from "./material";
import { useContext, useEffect, useState } from "react";
import DeviceWidth from "context/MobileResizeContext";
import { Link, Router } from "@reach/router";
import MaterialDetail from "./material-detail";
import axiosConfig from "config/axios";
import useMaterials from "hooks/useMaterials";
import useCategories from "hooks/useCategories";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";
// import debounce from "lodash.debounce";

const { Text } = Typography;
const { Panel } = Collapse;

const MaterialsPage = (props: ReachPathProps) => {
  // const [isSearching, setIsSearching] = useState<boolean>(false);
  // const [category, setCategory] = useState([]);
  // const [searchItems, setSearchItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const { materials, isLoading, getMaterials } = useMaterials();
  const { category, getCategories } = useCategories();

  const isMobile = useContext(DeviceWidth);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  let searchResult = materials.filter((mat: any) => {
    return (
      mat.brand.brandName.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      mat.category.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const getBrands = () => {
    return axiosConfig
      .get("getbrands")
      .then(
        ({
          data: {
            data: { brands },
          },
        }) => {
          setBrands(brands);
        }
      )
      .catch((error) => {
        requestErrorHandler(error);
      });
  };

  useEffect(() => {
    Promise.all([getCategories(), getMaterials(), getBrands()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterMenu = (
    <Menu style={{ width: "230px", textAlign: "center" }}>
      <Collapse bordered={false}>
        <Panel showArrow={false} header="brands" key="1">
          <Collapse accordion>
            {brands &&
              brands.map((brand: any) => {
                return (
                  <Panel
                    style={{ textAlign: "left" }}
                    header={brand.brandName}
                    key={brand.brandId}
                  >
                    {materials &&
                      materials.category &&
                      materials.map((material: any) => {
                        return (
                          material.brand?.brandName === brand.brandName && (
                            <div className={styles["filter-item"]}>
                              <Link to={`material-detail/${material.id}`}>
                                <img
                                  src={material.imageUrl}
                                  alt={material.category?.name}
                                  width="20"
                                  height="20"
                                  style={{
                                    borderRadius: "10px",
                                    marginRight: "1rem",
                                  }}
                                />
                                {material.category.name}
                              </Link>
                            </div>
                          )
                        );
                      })}
                  </Panel>
                );
              })}
          </Collapse>
        </Panel>
      </Collapse>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        {!isMobile && <Text>Inventory</Text>}
        <Space className={styles["left-section"]}>
          {!isMobile && (
            <Input
              prefix={<SearchOutlined />}
              // onChange={handleSearch}
              allowClear
              onChange={(e) => handleSearch(e)}
              placeholder="search"
              size="middle"
              style={{ width: "200px" }}
            />
          )}
          <Dropdown overlay={filterMenu} trigger={["click"]}>
            <Button type="default" onClick={(e) => e.preventDefault}>
              Filter <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      </div>
      <div className={`${styles[`main-body`]} ${styles.index}`}>
        {isMobile && (
          <Input
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e)}
            placeholder="search"
            size="small"
            style={{ width: "100%" }}
          />
        )}

        <Material
          isSearching={isSearching}
          searchItems={searchResult}
          category={category}
          materials={materials}
          isLoading={isLoading}
          callback={() => {
            getMaterials();
            getCategories();
          }}
        />
        {!isLoading && !category?.length && (
          <Empty
            description={
              <>
                <p>
                  You have not been profiled to sell any materials with
                  MaterialsPro.
                </p>
                <p>
                  Please contact customer support if this persists after 24
                  hours.
                </p>
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

const Materials = () => {
  const isDetailsPage = window.location.pathname.includes("detail");
  return (
    <DashboardShared title={isDetailsPage ? `Details` : `Inventory`}>
      <Router>
        <MaterialsPage path="/" />
        <MaterialDetail path="material-detail/:id" />
      </Router>
    </DashboardShared>
  );
};
export default Materials;
