import { message } from "antd";
import axios from "config/axios";
import { useState } from "react";
import useAuth from "./useAuth";
import requestErrorHandler from "util/requestErrorHandler";

const useCategories = () => {
  const { supplier } = useAuth();
  const [category, setCategory] = useState([]);
  const getCategories = (callback?: any) => {
    axios
      .get(`suppliercategory/${supplier?.id}`)
      .then(
        ({
          data: {
            data: { supplierCategories },
          },
        }) => {
          setCategory(supplierCategories);
        }
      )
      .catch((error) => {
        requestErrorHandler(error);
      });
  };

  const addCategory = (values: any, callback?: () => any) => {
    axios
      .post("category", { ...values })
      .then(() => {
        message.success({
          content: "Category created",
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);

        callback?.();
      });
  };

  const editCategory = (id: any, values: {}, callback?: () => any) => {
    axios
      .put(`category/${id}`, { ...values })
      .then(() => {
        message.success({
          content: "Category updated",
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
        callback?.();
      });
  };

  const deleteCategory = (id: any, callback?: () => any) => {
    axios
      .delete(`category/${id}`)
      .then(() => {
        message.success({
          content: `category deleted`,
        });
        callback?.();
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  };

  return { category, getCategories, addCategory, editCategory, deleteCategory };
};

export default useCategories;
