import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import requestErrorHandler from "util/requestErrorHandler";

type Props = {
  isModalOpen: boolean;
  getBankAccounts: () => Promise<void>;
  onCancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function CreateBankModal({
  isModalOpen,
  getBankAccounts,
  onCancel,
}: Props) {
  const [form] = Form.useForm();
  const {
    user: { organizationId },
  } = useAuth() as { user: User };
  const [isSubmitting, setIsSubmitting] = useState(false);
  async function handleSubmit() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await axios.post("account", { organizationId, ...form.getFieldsValue() });
      form.resetFields();
      onCancel();
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <Modal
      open={isModalOpen}
      afterClose={getBankAccounts}
      centered
      title="Add New Bank Account"
      onCancel={onCancel}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          rules={[{ required: true, message: "Type in a bank" }]}
          name="bankName"
          label="Bank Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Type in an account name" }]}
          name="accountName"
          label="Account Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Type in a account number" }]}
          name="accountNumber"
          label="Account Number"
        >
          <Input
            type="number"
            className="w-100"
            minLength={10}
            maxLength={10}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" block type="primary" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
