import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import sidenav from "./slices/sidenav";

export const store = configureStore({
  reducer: { auth, sidenav },
  devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
