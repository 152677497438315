import { Modal, Form, Input, Button, Select } from "antd";
import SearchPlace from "components/SearchPlace";
import axiosConfig from "config/axios";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

type Props = {
  isEditOpen: boolean;
  onCancel: () => void;
};

export default function EditBusinessInfo({ isEditOpen, onCancel }: Props) {
  const { supplier } = useAuth() as {
    supplier: Supplier;
  };
  const [form] = Form.useForm<User>();
  const [localGovernment, setLocalGovernmanet] = useState<any>([]);
  const [selectedLocalGovernment, setSelectedLocalGovernmanet] = useState<any>(
    []
  );
  const [
    selectedLocalGovernmentSearchText,
    setSelectedLocalGovernmentSearchText,
  ] = useState<string>("");
  const filteredSelectedLocalGovernments = useMemo(() => {
    if (!selectedLocalGovernmentSearchText) {
      // If the search text is empty, return all services
      return selectedLocalGovernment;
    }
    const lowerCaseSelectedLocalGovernmentSearchText =
      selectedLocalGovernmentSearchText.toLowerCase();
    return selectedLocalGovernment.filter((lga: any) =>
      lga.toLowerCase().includes(lowerCaseSelectedLocalGovernmentSearchText)
    );
  }, [selectedLocalGovernmentSearchText, selectedLocalGovernment]);

  async function handleSubmit(values: any) {
    try {
      await axios.put("supplier/editprofile", {
        supplierId: supplier.id,
        ...values,
      });
      onCancel();
    } catch (error) {
      requestErrorHandler(error);
    }
  }

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    const ordered = localGovernment?.filter(
      (lga: any) => newValue === lga.state
    );
    const list = ordered.length ? ordered[0].Lgas : null;
    setSelectedLocalGovernmanet(list);
    // setStateSearchText("");
  };

  const getLGA = async () => {
    await axiosConfig
      ?.get("nigerianstate")
      ?.then((res) => {
        setLocalGovernmanet(res.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getLGA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancel = () => {
    form.resetFields();
    onCancel();
  };
  console.log({ supplier });
  return (
    <Modal
      destroyOnClose
      footer={null}
      open={isEditOpen}
      title={"Edit Profile"}
      onCancel={cancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={supplier}
      >
        <Form.Item
          rules={[{ required: true, message: "Business name cannot be blank" }]}
          name="businessName"
          label="Business name"
          data-testid="b_name"
        >
          <Input />
        </Form.Item>
        <SearchPlace
          name="businessAddress"
          placeName=""
          label="Business Address"
          rules={[
            { required: true, message: "Business address cannot be blank" },
          ]}
          form={form}
        />
        <Form.Item
          rules={[{ required: true, message: "State cannot be blank" }]}
          name="state"
          label="State"
          data-testid="state"
        >
          <Input onChange={handleChange} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Zip code cannot be blank" }]}
          name="zipCode"
          label="Zip code"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="localGovernment"
          label="LGA"
          rules={[{ required: true }]}
        >
          <Select
            disabled={selectedLocalGovernment.length < 1}
            showSearch
            onSearch={(value: string) => {
              setSelectedLocalGovernmentSearchText(value);
            }}
            onChange={(value) => {
              //@ts-ignore
              form.setFieldsValue({ localGovernment: value });
              setSelectedLocalGovernmentSearchText("");
            }}
          >
            {filteredSelectedLocalGovernments.map((lgas: any) => (
              <Select.Option value={lgas} key={lgas}>
                {lgas}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" block type="primary" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
