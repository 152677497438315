import { navigate } from "@reach/router";
import { message } from "antd";
import axiosConfig from "config/axios";
import { useState } from "react";
import requestErrorHandler from "util/requestErrorHandler";

const useMaterials = () => {
  const [materials, setMaterials] = useState<[] | any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getMaterials = (params?: any, callback?: any) => {
    axiosConfig
      .get("material", {
        params: { ...params },
      })
      .then(
        ({
          data: {
            data: { materials },
          },
        }) => {
          setMaterials(materials);

          setIsLoading(false);
          callback && callback();
        }
      )
      .catch((error) => {
        setIsLoading(false);
        requestErrorHandler(error);
        callback?.();
      });
  };

  const addMaterial = (values: any, callback?: () => any) => {
    axiosConfig
      .post("material", { ...values })
      .then(() => {
        message.success({
          content: "Material created",
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
        callback?.();
      });
  };

  const editMaterial = (id: any, values: {}, callback?: () => any) => {
    axiosConfig
      .put(`material/${id}`, { ...values })
      .then(() => {
        message.success({
          content: "Material updated",
        });
        callback && callback();
      })
      .catch((error) => {
        requestErrorHandler(error);
        callback?.();
      });
  };

  const deleteMaterial = (id: any) => {
    axiosConfig.delete(`material/${id}`).then(() => {
      message.success({
        content: "Material deleted",
      });
      navigate("../");
    });
  };

  return {
    materials,
    isLoading,
    getMaterials,
    addMaterial,
    editMaterial,
    deleteMaterial,
  };
};

export default useMaterials;
