import styles from "./cards.module.css";
import { Typography } from "antd";

const { Text, Title } = Typography;
interface itemProps {
  image?: string;
  title: string | JSX.Element;
  subTitle?: string;
  topRight?: string;
  bottomRight?: string | JSX.Element;
}
const ItemCard = ({
  image,
  title,
  subTitle,
  topRight,
  bottomRight,
}: itemProps) => {
  return (
    <div className={styles.item}>
      {image && (
        <div className={styles["item-img"]}>
          <img src={image} alt="item" width="50" height="50" />
        </div>
      )}
      <div className={styles["item-text"]}>
        <div className={styles["item-main-text"]}>
          <Title style={{ fontSize: ".9rem" }}>{title}</Title>
          <Text
            style={{ marginLeft: "2px", display: "flex", fontSize: ".8rem" }}
          >
            {topRight}
          </Text>
        </div>
        <div className={styles["item-footer"]}>
          <Text style={{ width: "85%", fontSize: ".8rem" }}>{subTitle}</Text>
          <Text style={{ fontSize: ".8rem" }}>{bottomRight}</Text>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
