import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import TopTab from "components/TopTab/TopTab";
import Bank from "./bank";
import Business from "./business";
import Profile from "./profile-view";
import UserManagement from "./user-management";
import React from "react";

export default function ProfilePage(props: ReachPathProps) {
  const getTitle = window.location.pathname.includes("profile")
    ? "Profile"
    : window.location.pathname.includes("user")
    ? "User Management"
    : window.location.pathname.includes("business")
    ? "Business Info"
    : window.location.pathname.includes("bank") && "Bank Account";
  return (
    <DashboardShared title={getTitle}>
      <TopTab
        links={[
          { to: "", name: "Company Profile" },
          { to: "profile", name: "Company Representative" },
          { to: "user-management", name: "User Management" },
          { to: "bank-account", name: "Bank Account" },
        ]}
      />
      <Router>
        <Business path="/" />
        <Profile path="/profile" />
        <UserManagement path="/user-management" />
        <Bank path="/bank-account" />
      </Router>
    </DashboardShared>
  );
}
