import { Modal, Form, Input, Button, Checkbox, Col, Row, message } from "antd";
import useAuth from "hooks/useAuth";
import axios from "config/axios";
import requestErrorHandler from "util/requestErrorHandler";

type Props = {
  isModalOpen: boolean;
  onCancel: () => void;
};

export default function AddUser({ isModalOpen, onCancel }: Props) {
  const [form] = Form.useForm();
  const { supplier } = useAuth();
  async function handleSubmit(values: any) {
    try {
      await axios.post("user/token", {
        ...values,
        organizationId: supplier?.organizationId,
      });
      message.success("User added successfully");
      onCancel();
      form.resetFields();
    } catch (error) {
      requestErrorHandler(error);
    }
  }

  return (
    <Modal
      footer={null}
      open={isModalOpen}
      title={"Add a User"}
      onCancel={onCancel}
      data-testid="supplier-modal"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          rules={[
            { required: true, message: "Email cannot be blank" },
            { type: "email", message: "Please enter a valid email" },
          ]}
          name="email"
          label="Input User's Email"
        >
          <Input data-testid="email-input" />
        </Form.Item>
        <Form.Item hidden name="permissions" label="Set user permissions">
          <Checkbox.Group className="w-100">
            <Row gutter={[10, 10]}>
              <Col>
                <Checkbox value="todo">Todo</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item>
          <Button
            data-testid="submit-btn"
            htmlType="submit"
            block
            type="primary"
            size="large"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
