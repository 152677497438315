import {
  message,
  Button,
  Divider,
  Form,
  Modal,
  Select,
  InputNumber,
} from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import styles from "./styles.module.css";
import axiosConfig from "../../../../config/axios";
import useAuth from "hooks/useAuth";
import requestErrorHandler from "util/requestErrorHandler";

const { Option } = Select;

interface modalProps {
  materialId: any;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
  variants: any;
}

const AddMaterial = ({
  materialId,
  showModal,
  setShowModal,
  variants,
  callback,
}: modalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { supplier } = useAuth();
  const [form] = Form.useForm();
  const action = () => {
    setIsSubmitting(false);
    setShowModal(false);
    callback && callback();
  };
  const handleSubmit = async (values: {}) => {
    setIsSubmitting(true);
    setFieldsValue({
      materialId: materialId,
    });
    await axiosConfig
      .post("inventory/add", { ...values, supplierId: supplier?.id })
      .then(() => {
        message.success({
          content: "Inventory Added Successfully",
        });
        action();
      })
      .catch((error) => {
        requestErrorHandler(error);
        action();
      })
      .finally(() => setIsSubmitting(false));
    resetFields();
  };
  const { resetFields, setFieldsValue } = form;
  return (
    <Modal
      className={styles.modal}
      title="Add new Variant"
      onCancel={() => setShowModal(false)}
      onOk={() => setShowModal(false)}
      visible={showModal}
      footer={false}
    >
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item
          label="Variant"
          name="variantId"
          rules={[{ required: true, message: "Please select a variant" }]}
        >
          <Select placeholder="Select a variant">
            {variants?.map((variant: any) => (
              <Option key={variant.id} value={variant.id}>
                {variant.description}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Quantity"
          name="qty"
          rules={[
            { required: true, message: "Please enter a valid quantity" },
            { type: "number", message: "Please enter a valid quantity" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const variant = variants.find(
                  (v: any) => v.id === getFieldValue("variantId")
                );
                if (!value && !variant) {
                  return Promise.resolve();
                }
                if (!value || variant.minSalesQty <= value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    `Quantity must be greater than or equal to ${variant.minSalesQty}`
                  )
                );
              },
            }),
          ]}
        >
          <InputNumber className="w-100" />
        </Form.Item>
        <Form.Item
          label="Set Unit Price"
          name="unitPrice"
          rules={[{ required: true, message: "Please enter price" }]}
        >
          <InputNumber min={0} className="w-100" />
        </Form.Item>

        <Form.Item
          label="Minimum fulfilment period (Days)"
          name="minimumFuilment"
          rules={[
            {
              required: true,
              message: "Please set a minimun fulfilment period",
            },
          ]}
        >
          <InputNumber min={0} className="w-100" />
        </Form.Item>
        <Divider />
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          style={{ marginTop: "-300px", width: "100%" }}
        >
          Add to inventory
        </Button>
      </Form>
    </Modal>
  );
};

export default AddMaterial;
