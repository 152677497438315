import { Link } from "@reach/router";
import { Divider, Empty, Typography } from "antd";
import styles from "./material.module.css";
import SkeletonInput from "antd/lib/skeleton/Input";
// import ItemCard from "components/Cards/ItemCard";
import MaterialCard from "components/Cards/ItemCard/MaterialCard";

const { Text } = Typography;

interface MaterialProps {
  [materials: string]: any;
  category: any;
  isSearching?: boolean;
  searchItems?: any;
  isLoading: boolean;
  callback?: () => void;
}

const MaterialsSkeleton = () => {
  return (
    <div className={styles["material-container"]}>
      <Text className={styles["material-header"]}>{"cat.name"}</Text>
      <Divider style={{ margin: ".5rem" }} orientation="center" />
      <div className={styles["material-items"]}>
        <SkeletonInput
          style={{ width: 250, height: "80px" }}
          active={true}
          size={`large`}
        />
        <SkeletonInput
          style={{ width: 250, height: "80px" }}
          active={true}
          size={`large`}
        />
        <SkeletonInput
          style={{ width: 250, height: "80px" }}
          active={true}
          size={`large`}
        />
      </div>
    </div>
  );
};

const Material = ({
  materials,
  category,
  isSearching,
  searchItems,
  isLoading,
}: MaterialProps) => {
  !isLoading && <MaterialsSkeleton />;

  const MaterialItem = isLoading ? (
    <div className={styles["material-container"]}>
      <Text className={styles["material-header"]}>
        <SkeletonInput
          style={{ width: "100px", height: "25px", borderRadius: "10px" }}
          active={true}
          size={`large`}
        />
      </Text>
      <Divider style={{ margin: ".5rem" }} orientation="center" />
      <div className={styles["material-items"]}>
        {[1, 2, 3].map((i) => (
          <SkeletonInput
            key={i}
            style={{ width: "280px", height: "100px", borderRadius: "5px" }}
            active={true}
            size="large"
          />
        ))}
      </div>
    </div>
  ) : (
    category.map((cat: any) => {
      return (
        <div key={cat.id} className={styles["material-container"]}>
          <div className={styles["material-header"]}>
            <Text>{cat.name}</Text>
          </div>
          <Divider style={{ margin: ".5rem" }} orientation="center" />
          <div className={styles["material-items"]}>
            {materials &&
              materials.map((mat: any) => {
                return (
                  cat.id === mat.categoryId && (
                    <>
                      <div key={mat.id}>
                        <Link to={`material-detail/${mat.id}`}>
                          <MaterialCard
                            image={mat.imageUrl}
                            title={mat.brand.brandName}
                            subTitle={mat.category?.name}
                            topRight={
                              mat.variants.length > 1
                                ? `${mat.variants.length} variants`
                                : `${mat.variants.length} variant`
                            }
                            bottomRight=""
                          />
                        </Link>
                      </div>
                    </>
                  )
                );
              })}
          </div>
        </div>
      );
    })
  );

  const searchResult = (
    <div className={styles["material-container"]}>
      <div className={styles["material-items"]}>
        {searchItems &&
          searchItems.map((mat: any) => {
            return (
              <>
                <div key={mat.id}>
                  <Link to={`material-detail/${mat.id}`}>
                    <MaterialCard
                      image={mat.imageUrl}
                      title={mat.brand.brandName}
                      subTitle={mat.category?.name}
                      topRight={
                        mat.variants.length > 1
                          ? `${mat.variants.length} variants`
                          : `${mat.variants.length} variant`
                      }
                    />
                  </Link>
                </div>
              </>
            );
          })}
        {!searchItems && <Empty />}
      </div>
    </div>
  );

  return <>{isSearching ? searchResult : MaterialItem}</>;
};

export default Material;
