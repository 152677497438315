import { Spin } from "antd";

export default function PageSpinner({
  fullScreen,
  title,
}: {
  fullScreen?: boolean;
  title?: string;
}) {
  return (
    <div
      style={{
        width: "100%",
        height: fullScreen ? "100vh" : "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div
        data-testid="spinner"
        style={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <Spin />
        {title && (
          <p
            style={{
              fontSize: "16px",
              // marginTop: "1rem",
            }}
          >
            Verifying payment...
          </p>
        )}
      </div>
    </div>
  );
}
