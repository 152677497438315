import { Space } from "antd";
import { Menu } from "react-feather";
import { useDispatch } from "react-redux";
import Bell from "../../assets/icons/Bell";
import { toggle } from "../../redux/slices/sidenav";
import styles from "./dashshared.module.css";
import useAuth from "../../hooks/useAuth";

type DashboardSharedProps = {
  title?: string | any;
  children: React.ReactNode;
};
export default function DashboardShared(props: DashboardSharedProps) {
  const { supplier } = useAuth();
  const dispatch = useDispatch();
  return (
    <div className={styles.main}>
      <div className={styles.topbar}>
        <h1>{props.title} &nbsp;</h1>
        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          <h4 style={{ margin: 0 }}>
            {" "}
            👋 <span style={{ fontWeight: "lighter" }}>Hello </span>{" "}
            {supplier?.businessName}
          </h4>
          <button className={styles.bell}>
            <Bell />
          </button>
        </Space>
        <button
          data-testid="menu-button"
          className={styles.toggle}
          onClick={() => {
            dispatch(toggle());
          }}
        >
          <Menu />
        </button>
      </div>
      <div className={styles.content}>
        <div>{props.children}</div>
      </div>
    </div>
  );
}
