import AuthFormShared, {
  AuthButton,
} from "../../components/AuthFormShared/AuthFormShared";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { navigate, Redirect } from "@reach/router";
import axiosConfig from "config/axios";
import useAuth from "../../hooks/useAuth";
import styles from "./signIn.module.css";
import requestErrorHandler from "util/requestErrorHandler";
import React from "react";

export default function SignIn(props: ReachPathProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { user, logIn } = useAuth();
  const builderUrl = process.env.REACT_APP_BUILDER_URL;
  const dspUrl = process.env.REACT_APP_TRANSPORTER_URL;
  const homeUrl = process.env.REACT_APP_HOME_URL;
  async function handleSubmit(values: unknown) {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await axiosConfig.post(
        `${process.env.REACT_APP_BACKEND_URL}/supplier/login`,
        values
      );
      const { supplier, user, token } = data;
      logIn(user, supplier, token);
    } catch (error: any) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }
  if (user) return <Redirect from="/" to="/suppliers/dashboard" noThrow />;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles["auth-form"]}>
          <AuthFormShared title="Sign In as a Supplier" {...props}>
            <div className="w-100">
              <Form onFinish={handleSubmit} form={form} layout="vertical">
                <Form.Item
                  name="adminEmail"
                  label="Email Address"
                  rules={[{ required: true }, { type: "email" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="adminPassword"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  style={{ margin: 0 }}
                >
                  <Input.Password />
                </Form.Item>
                <div style={forgot_password}>
                  <Button
                    type="link"
                    onClick={() => navigate("forgot-password")}
                  >
                    Forgot password?
                  </Button>
                </div>
                <AuthButton isLoading={isLoading}>Sign In</AuthButton>
              </Form>
              <p className="text-center">
                Don’t have an account?{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${homeUrl}/signup.html?orgType=supplier`}
                >
                  Register
                </a>
              </p>
              <p className="text-center">
                <a target="_blank" href={`${builderUrl}`} rel="noreferrer">
                  Sign in as Builder
                </a>{" "}
                |{" "}
                <a rel="noreferrer" target="_blank" href={`${dspUrl}`}>
                  Sign in as DSP
                </a>
              </p>
            </div>
          </AuthFormShared>
        </div>
      </div>
    </div>
  );
}

const forgot_password = {
  marginBottom: "1rem",
  wiidth: "100%",
  display: "flex",
  justifyContent: "flex-end",
};
