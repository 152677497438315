import {
  Skeleton,
  Table,
  Button,
  Input,
  Space,
  Row,
  Col,
  message,
  Modal,
  Alert,
  Form,
  InputNumber,
  DatePicker,
} from "antd";
import Text from "antd/lib/typography/Text";
import DeviceWidth from "context/MobileResizeContext";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "./quotations.module.css";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "config/axios";
import dayjs from "dayjs";
import useAuth from "hooks/useAuth";
import { navigate } from "@reach/router";
import moment from "moment";
import requestErrorHandler from "util/requestErrorHandler";
import React from "react";

const QuotationsView = ({ id }: ReachPathProps & { id?: string }) => {
  const isMobile = useContext(DeviceWidth);
  const [isOpen, setIsOpen] = useState(false);
  const { supplier } = useAuth();

  const [quotation, setQuotation] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quotationItems, setQuotationItems] = useState<any[]>([]);

  const getQuotation = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { inventory },
        },
      } = await axios.get(`inventory/all/${supplier?.id}`);

      const {
        data: { data },
      } = await axios.get(`quotation/${id}`);
      // .then((res) => {
      //   setQuotation(res.data.data);
      // })
      setQuotation(data);

      const viewables = data.quotation_items.filter((item: any) =>
        inventory.some((content: any) => content.variantId === item.variantId)
      );

      setQuotationItems(viewables);
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [id, supplier?.id]);

  useEffect(() => {
    getQuotation();
  }, [getQuotation]);
  const material = {};

  const webTableColumns = [
    {
      title: "MATERIAL",
      dataIndex: "variant",
      key: "variant",
      render: (text: Variant) => <Text>{text.description}</Text>,
    },
    {
      title: "BRAND",
      dataIndex: "variant",
      key: "brand",
      render: (text: Variant) => <Text>{text.description}</Text>,
    },
    {
      title: "SIZE",
      dataIndex: "variant",
      key: "size",
      render: (text: Variant) => (
        <Text>
          {text.unitSize} {text.material.unitOfMeasure}
        </Text>
      ),
    },
    {
      title: "QUANTITY",
      dataIndex: "quotation_details",
      key: "quantity",
      render: (text: any[]) =>
        text.length ? (
          <Text>{text.reduce((a, c) => a + c.quantity, 0)}</Text>
        ) : null,
    },
    {
      title: "UNIT PRICE",
      dataIndex: "variant",
      key: "unitPrice",
      render: (text: Variant) => <Text>{text.unitPrice.toLocaleString()}</Text>,
    },
    {
      title: "TOTAL",
      dataIndex: "detailsPrice",
      key: "detailsPrice",
      render: (text: string) => (
        <div className={styles.total}>
          <>
            {text.toLocaleString()}
            <Button type="text" className={styles["more-button"]}>
              <MoreOutlined />
            </Button>{" "}
          </>
        </div>
      ),
    },
  ];

  if (isLoading || !quotation) {
    return <div className={styles.container}></div>;
  }

  const prices = [];
  quotationItems.map((item) => prices.push(item.detailsPrice));
  // const totalPrice = prices.reduce((a, b) => a + b, 0);

  return (
    <div className={styles.container}>
      <div className={`${styles.topbar} ${styles["quotations-topbar"]}`}>
        <div>
          <Space
            style={{
              display: "flex",
              marginBottom: "1rem",
              justifyContent: "space-between",
            }}
          >
            {material && (
              <span>
                <Button
                  style={{ padding: 0 }}
                  type="text"
                  onClick={() => navigate("suppliers/quotation")}
                >
                  Quotation{" "}
                </Button>
                <span style={{ opacity: ".5" }}>
                  {" "}
                  {`|  ${id?.substring(0, 7)}`}
                </span>
              </span>
            )}
            <Button
              onClick={() => setIsOpen(true)}
              style={{ textTransform: "uppercase", marginLeft: "auto" }}
            >
              Submit quotation
            </Button>
          </Space>
          {!material && (
            <span>
              <Skeleton.Input
                style={{
                  width: "100px",
                  height: "25px",
                  borderRadius: "10px",
                }}
                active={true}
                size={`large`}
              />
            </span>
          )}
        </div>
      </div>
      <div className={styles["quotations-card-container"]}>
        {!material && (
          <>
            <Skeleton.Input
              style={{
                width: `${isMobile ? "100%" : "280px"}`,
                height: "130px",
                borderRadius: "15px",
              }}
              active={true}
              size={`large`}
            />
            <Skeleton.Input
              style={{
                width: `${isMobile ? "100%" : "280px"}`,
                height: "130px",
                borderRadius: "15px",
              }}
              active={true}
              size={`large`}
            />
            <Skeleton.Input
              style={{
                width: `${isMobile ? "100%" : "280px"}`,
                height: "130px",
                borderRadius: "15px",
              }}
              active={true}
              size={`large`}
            />
          </>
        )}
        {material && (
          <Row
            gutter={[10, 10]}
            justify="space-between"
            style={{ width: "100%", marginBottom: "2rem" }}
          >
            <Col xs={24} sm={24} md={11} lg={10}>
              <div className={styles["quotations-card"]}>
                <span>
                  Quotation ID
                  <b>{quotation.id.substring(0, 7)}</b>
                </span>
                <span>
                  Quotation date
                  <b>{dayjs(quotation.createdAt).format("DD MM YYYY")}</b>
                </span>
                {/* <span>
                    Status
                    <b>
                      <Text type="warning"></Text>
                    </b>
                  </span> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={11} lg={10}>
              <div className={styles["quotations-card"]}>
                <span>
                  <Text>Owned by</Text>
                  <b>{quotation.builder.businessName}</b>
                </span>
                <span>
                  <Text>Expiry date</Text>
                  <b>{dayjs(quotation.expirationDate).format("DD MM YYYY")}</b>
                </span>
                {/* <span>
                    <Text>No of entries</Text>
                    <b>--</b>
                  </span> */}
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Alert
        type="info"
        message="Only materials you have in your inventory are displayed"
        showIcon={false}
      />
      <div className={styles["main-body"]}>
        <div>
          <div className={styles["body-topbar"]}>
            <Text style={{ textTransform: "capitalize" }}>
              {quotation.builder.businessName}
            </Text>
            <Space>{!isMobile && <Input prefix={<SearchOutlined />} />}</Space>
            {isMobile && (
              <Input
                style={{ width: "100%", margin: "15px auto 0px" }}
                prefix={<SearchOutlined />}
              />
            )}
          </div>

          <Table
            pagination={false}
            columns={webTableColumns}
            dataSource={quotationItems}
            className={styles.table}
            loading={isLoading}
            style={{
              fontSize: "0.2rem",
              overflowX: "auto",
              textTransform: "capitalize",
            }}
          />
        </div>
      </div>

      <div className={styles["foot-note"]}>
        <div className={styles["quotations-card"]}>
          <span>
            <Text>Total</Text>
            <b>{`₦${quotation.materialPrice.toLocaleString()}`}</b>
          </span>
        </div>
      </div>
      <Process
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        quotationItems={quotationItems}
      />
    </div>
  );
};

function Process({
  isOpen,
  setIsOpen,
  quotationItems,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  quotationItems: any[];
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { supplier } = useAuth();

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  const tableColumns = [
    {
      title: "Material",
      dataIndex: "variant",
      key: "material",
      render: (text: Variant) => text.description,
    },
    {
      title: "Quantity",
      dataIndex: "quotation_details",
      key: "quantity",
      render: (text: any[]) =>
        text.length ? (
          <Text>{text.reduce((a, c) => a + c.quantity, 0)}</Text>
        ) : null,
    },
    {
      title: "TOTAL",
      dataIndex: "detailsPrice",
      key: "detailsPrice",
      render: (text: number) => <Text>{`₦${text.toLocaleString()}`}</Text>,
    },
    {
      title: "Unit Price",
      dataIndex: "id",
      key: "id",
      render: (text: string) => (
        <>
          <Form.Item
            name="supplierPrice"
            rules={[
              { required: true, message: "Input a price" },
              { type: "number" },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="quotationItemId"
            rules={[{ required: true, message: "Input a price" }]}
            initialValue={text}
          >
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Expiry date",
      dataIndex: "id",
      key: "id",
      render: (text: string) => (
        <Form.Item
          name="expiryDate"
          rules={[{ required: true, message: "Input a expiry date" }]}
        >
          <DatePicker
            format="DD MMMM YYYY"
            picker="week"
            disabledDate={disabledDate}
          />
        </Form.Item>
      ),
    },
  ];
  return (
    <Modal
      visible={isOpen}
      title="Process quotation"
      footer={null}
      width={800}
      onCancel={() => setIsOpen(false)}
    >
      <Form
        onFinish={async (value) => {
          if (isLoading) return;
          setIsLoading(true);
          try {
            await axios.post("quotation/offer", {
              supplierId: supplier?.id,
              ...value,
            });
            setIsOpen(false);
            message.success("Quotation offer sent successfully");
          } catch (error) {
            requestErrorHandler(error);
          }
          setIsLoading(false);
        }}
      >
        <Table
          pagination={false}
          size="small"
          columns={tableColumns}
          dataSource={quotationItems}
        />
        <Alert
          message="Please note:"
          description="Quotation items can be selected individually by a buyer."
          closable
          type="warning"
          showIcon
        />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            size="large"
          >
            Submit Quotation
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default QuotationsView;
