import { Router } from "@reach/router";

import { Suspense, useEffect, useState } from "react";
import { MobileResizeContext } from "./context/MobileResizeContext";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Suppliers from "./pages/suppliers";
import axios from "config/axios";
import useAuth from "./hooks/useAuth";
import Home from "pages";
import "./App.less";
import "./global.css";
import NotFound from "pages/404";
import ForgotPassword from "pages/ForgotPassword";
import ResetPassword from "pages/ForgotPassword/reset";
import requestErrorHandler from "util/requestErrorHandler";
import PageSpinner from "components/PageSpinner";

export default function App() {
  const [isValidating, setIsValidating] = useState(true);
  const { logIn, updateToken, logOut } = useAuth();
  useEffect(() => {
    const token = localStorage.getItem("token-supplier-v1");
    (async function () {
      try {
        if (token) {
          updateToken(token);
          const {
            data: { data: user },
          } = (await axios.get("user")) as { data: { data: User } };
          const {
            data: { data: supplier },
          } = await axios.get(`supplier/getonesupplier/${user.organizationId}`);
          logIn(user, supplier.supplier[0]);
        }
      } catch (error) {
        requestErrorHandler(error);
        logOut();
      } finally {
        setIsValidating(false);
      }
    })();
  }, [logIn, logOut, updateToken]);
  if (isValidating) return <></>;

  return (
    <MobileResizeContext>
      <Suspense fallback={<PageSpinner />}>
        <Router>
          <Home path="/" />
          <SignUp path="/signup/proceed" />
          <SignIn path="/login" />
          <Suppliers path="suppliers/*" />
          <ForgotPassword path="forgot-password" />
          <ResetPassword path="forgot-password/reset" />
          <NotFound path="*" />
        </Router>
      </Suspense>
    </MobileResizeContext>
  );
}
