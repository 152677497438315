import { Input, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./profile.module.css";
import classNames from "classnames";
import useAuth from "hooks/useAuth";
import axiosConfig from "config/axios";
import AddUser from "components/AddUser/AddUser";
import useSearch from "hooks/useSearch";
import requestErrorHandler from "util/requestErrorHandler";

const columns = [
  {
    title: "FIRST NAME",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "LAST NAME",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    key: "email",
    render: (text: any) => (
      <span style={{ textTransform: "lowercase" }}>{text}</span>
    ),
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "USER ROLE",
    dataIndex: "role",
    key: "role",
    render: () => "Super Admin",
  },
];

export default function UserManagement(props: ReachPathProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState<boolean>();
  const { handleSearch, isSearching, searchQuery } = useSearch();
  let searchResult = users.filter((user: User) => {
    return (
      user.firstName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      user.lastName.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      user.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      user.phoneNumber.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      user.role.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  const {
    user: { organizationId },
  } = useAuth() as { user: User };
  const getUsers = useCallback(
    async function getUsers() {
      setLoading(true);
      try {
        const {
          data: {
            data: { organizationUsers },
          },
        } = await axiosConfig.get(`organization/${organizationId}/users`);
        setUsers(organizationUsers);
      } catch (error) {
        requestErrorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input
            onChange={(e) => handleSearch(e)}
            placeholder="search"
            prefix={<SearchOutlined />}
          />
        </div>
        {/* <Button onClick={() => setShowModal(true)} type="primary">
          Add User
        </Button> */}
      </div>
      <Table
        size="small"
        rowClassName={() => "table-row"}
        dataSource={isSearching ? searchResult : users}
        columns={columns}
        loading={loading}
      />
      <AddUser isModalOpen={showModal!} onCancel={() => setShowModal(false)} />
    </>
  );
}
