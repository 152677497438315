import { Typography, Button, Space, message, Alert, Statistic } from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";
// import DashboardShared from "components/DashboardShared/DashboardShared";
import styles from "./transactions.module.css";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import axiosConfig from "config/axios";
import PageSpinner from "components/PageSpinner";
import OrderTracking from "components/OrderTracking";
import requestErrorHandler from "util/requestErrorHandler";
import { useLocation } from "@reach/router";
import { parse } from "fast-qs";

const { Text } = Typography;

const TransactionDetail = (props: ReachPathProps & { id?: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const { search } = useLocation();

  const { type } = parse(search) as {
    type: string;
  };

  console.log({ type });

  const getTransactionInfo = useCallback(async () => {
    try {
      const {
        data: { transaction },
      } = await axiosConfig.get(
        type !== "homeowner"
          ? `transaction/gettransactionbytransid/${props.id}`
          : `homeowner/get-one-transaction?transactionId=${props.id}`
      );
      setTransaction(transaction);

      console.log({ transaction });
    } catch (error) {
      requestErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [props.id, type]);

  useEffect(() => {
    getTransactionInfo();
  }, [getTransactionInfo]);

  if (loading || !transaction) {
    return (
      <div className={` ${styles["details-container"]}`}>
        <PageSpinner />
      </div>
    );
  }
  return (
    <div className={` ${styles["details-container"]}`}>
      <div className={`${styles.topbar} ${styles["details-topbar"]}`}>
        <div>
          <Button
            type="text"
            style={{ padding: 0 }}
            onClick={() => navigate("/suppliers/transactions")}
          >
            Transaction
          </Button>

          <span style={{ opacity: ".5" }}>
            {" "}
            | {transaction?.order?.frontendId}
          </span>
        </div>
        <Space style={{ overflowX: "auto" }}>
          {transaction?.trackingStatus === "order_processed" ? (
            <Alert message="Order Processed, Awaiting Pickup" type="success" />
          ) : transaction?.trackingStatus === "order_created" ? (
            // <div>Order Processed, Awaiting Pickup.</div>
            <Button
              icon={<CheckSquareOutlined />}
              type="link"
              size="small"
              onClick={async () => {
                try {
                  await axios.post("supplier/confirmorderready", {
                    transactionId: transaction?.id,
                  });
                  getTransactionInfo();
                  message.success("Order confirmed");
                } catch (error) {
                  requestErrorHandler(error);
                }
              }}
            >
              Process order
            </Button>
          ) : (
            <></>
          )}

          {/* <Button
            onClick={() =>
              navigate(`/suppliers/transactions/invoice/${transaction?.id}`)
            }
            icon={<FileTextOutlined />}
            type="link"
            size="small"
          >
            Preview invoice
          </Button> */}
        </Space>
        <OrderTracking
          setShowModal={setShowModal}
          showModal={showModal}
          transaction={transaction}
        />
      </div>
      <div className={styles["details-card-container"]}>
        <div className={styles["details-card"]}>
          <span>
            Invoice ID
            <b>{transaction?.frontendId}</b>
          </span>
          <span>
            Order date
            <b>{dayjs(transaction?.createdAt).format("DD MMM YYYY")}</b>
          </span>
          {type === "homeowner" ? (
            <span>
              Homeowner
              <b>
                {transaction?.homeOwner?.firstName}{" "}
                {transaction?.homeOwner?.lastName}
              </b>
            </span>
          ) : (
            <span>
              Builder
              <b>{transaction?.builder?.businessName}</b>
            </span>
          )}
          <span>
            Payment status
            {transaction?.paymentStatus ? (
              <b style={{ color: "#00962C" }}>Paid</b>
            ) : (
              <b style={{ color: "#FF612F" }}>Unpaid</b>
            )}
          </span>
          <span>
            Tracking status
            {transaction?.trackingStatus === "delivered" ? (
              <b style={{ color: "#00962C" }}>Delivered</b>
            ) : (
              <b style={{ color: "#F2994A" }}>
                {" "}
                {transactionTracking(transaction)}{" "}
              </b>
            )}
          </span>
        </div>
        <div className={styles["details-card"]}>
          {/* <span>
            <Text>Delivery option</Text>
            <b>Deliver to address</b>
          </span> */}
          <span>
            <Text>Delivery address</Text>
            <b>{transaction?.deliveryAddress}</b>
          </span>
          {/* <span>
            <Text>State</Text>
            <b>Lagos, Nigeria</b>
          </span> */}
          <span>
            <Text>Supplier</Text>
            <b>{transaction?.supplier?.businessName}</b>
          </span>
          {/* {transaction?.deliveryPartner ? (
            <span>
              <Text>Delivery partner</Text>
              <b>Fastex delivery solution</b>
            </span>
          ) : (
            ""
          )} */}
          <span>
            <Text>Expected delivery date</Text>
            <b>{dayjs(transaction?.deliveryDate).format("DD MM YYYY")}</b>
          </span>
        </div>
      </div>
      <div className={styles["main-body"]}>
        <div className={styles["profile-card"]}>
          <div className={styles.circle} />
          <div className={styles.circle} />

          <img
            src={transaction?.variant?.material?.imageUrl}
            alt={`${transaction?.variant?.material?.brand?.brandName} ${transaction?.variant?.material?.category?.name} ${transaction?.variant?.description}`}
            className={styles.avatar}
            height={200}
          />
          <div className={styles["profile-content"]}>
            <div className={styles.name}>
              <h2
                style={{ textTransform: "uppercase", margin: 0 }}
              >{`${transaction?.variant.material.brand?.brandName} ${transaction?.variant?.material?.category?.name}`}</h2>
              <h2
                style={{
                  textTransform: "uppercase",
                  opacity: 0.5,
                  margin: 0,
                }}
              >{`${transaction?.variant?.description}`}</h2>
            </div>
            <div className={styles.quantity}>
              {`${Math.round(transaction?.quantity)} ${
                type === "homeowner"
                  ? `Truck Load${transaction?.quantity > 1 ? "s" : ""}`
                  : transaction?.variant.material?.unitOfMeasure
              }`}
            </div>
            <div className={styles.quantity}>
              <Statistic prefix="₦" value={transaction?.order?.materialPrice} />{" "}
            </div>
            <div className={styles.email}>
              <p></p>
            </div>
            <div className={styles.phoneNumber}>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const transactionTracking = (transaction: Transaction) =>
  transaction.trackingStatus.replace(/_/g, " ");

export default TransactionDetail;
