import { Tooltip } from "antd";
import styles from "./dashboard.module.css";
import React from "react";

interface BarProps {
  currentCount?: string;
  minimum: number;
  title: string;
  date?: string;
}

const Bar = ({ currentCount, minimum, title, date }: BarProps) => {
  return (
    <>
      {currentCount && (
        <>
          <div>
            <div
              className={styles["bar-current-count"]}
              style={{ height: `${currentCount}px` }}
            />
            <div
              className={styles["bar-minimum"]}
              style={{ height: `${minimum}%` }}
            />
          </div>
          <div className={styles["bar-title"]}>{title}</div>
        </>
      )}
      {!currentCount && (
        <Tooltip className={styles.bar} title={`${title} | ${minimum}kg`}>
          <div className={styles["bar-container"]}>
            <div
              className={styles["bar-minimum"]}
              style={{
                height: `${minimum * 0.1}px`,
              }}
            />
          </div>
          <div className={styles["bar-title"]}>{date}</div>
        </Tooltip>
      )}
    </>
  );
};

export default Bar;
