import { Router } from "@reach/router";
import DashboardShared from "components/DashboardShared/DashboardShared";
import History from "./history";
import PaymentDetials from "./payment-details";
import Recent from "./recent";
import React from "react";
export default function Payments(props: ReachPathProps) {
  return (
    <DashboardShared title="Payments">
      <Router>
        <Recent path="/" />
        <PaymentDetials path="/:id" />
        <History path="history" />
      </Router>
    </DashboardShared>
  );
}
