import { Box, Grid, List, User, FileText, CreditCard } from "react-feather";
import DashBoard from "pages/suppliers/dashboard";
import Quotations from "pages/suppliers/get-quotations";
import Materials from "pages/suppliers/inventory";
import Profile from "pages/suppliers/profile";
import Payments from "pages/suppliers/payments";
import Transactions from "../pages/suppliers/transactions";
type DashboardRoute = {
  path: `${string}/*`;
  title: string;
  component: (props: ReachPathProps) => JSX.Element;
  icon: React.ReactNode;
};

const dashboardRoute: DashboardRoute[] = [
  {
    path: "dashboard/*",
    component: DashBoard,
    icon: <Grid />,
    title: "Dashboard",
  },
  {
    path: "transactions/*",
    component: Transactions,
    icon: <List />,
    title: "Transactions",
  },
  {
    path: "quotation/*",
    component: Quotations,
    icon: <FileText />,
    title: "Quotations",
  },
  {
    path: "materials/*",
    component: Materials,
    icon: <Box />,
    title: "Inventory",
  },
  {
    path: "payments/*",
    component: Payments,
    icon: <CreditCard />,
    title: "Payments",
  },

  { path: "profile/*", component: Profile, icon: <User />, title: "Profile" },
];

export default dashboardRoute;
