import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "redux/store";
import { ConfigProvider } from "antd";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
      // theme={{
      //   token: {
      //     colorPrimary: "#00962C",
      //     fontFamily: `'Montserrat', sans-serif`,
      //     colorHighlight: "#cfe3d5",
      //     blue: "#00962C",
      //     // colorText: "#00962C",
      //     colorPrimaryText: "#00962C",
      //     colorTextLabel: "#00962C",
      //     colorPrimaryTextActive: "#00962C",
      //     colorLink: "#00962C",
      //     colorPrimaryHover: "#00962C",
      //     colorLinkHover: "#00962C",
      //     colorInfoHover: "#00962C",
      //     colorLinkActive: "#00962C",
      //     colorText: "#272D4E",
      //     linkDecoration: "none",
      //     "red-1": "#F04437",
      //     colorError: "#F04437",
      //     // colorInfoHover: "rgb(231, 242, 247)",
      //     // colorInfoActive: "rgb(231, 242, 247)",
      //     // colorPrimaryActive: "rgb(231, 242, 247)",
      //   },
      // }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
