import { Button, Checkbox, Empty, Popconfirm } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import styles from "../profile/profile.module.css";
import axios from "config/axios";
import CreateBankModal from "components/CreateBankModal/CreateBankModal";
import useAuth from "../../../hooks/useAuth";
import PageSpinner from "components/PageSpinner";
import requestErrorHandler from "util/requestErrorHandler";
import React from "react";

export default function Bank(props: ReachPathProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accounts, setAccounts] = useState<BankAccount[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    user: { organizationId },
  } = useAuth() as { user: User };

  const getBankAccounts = useCallback(
    async function getBankAccounts() {
      setIsLoading(true);
      try {
        const {
          data: {
            data: { accounts },
          },
        } = await axios.get(`account/${organizationId}`);
        setAccounts(accounts);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        requestErrorHandler(error);
      }
    },
    [organizationId]
  );

  useEffect(() => {
    getBankAccounts();
  }, [getBankAccounts]);
  return (
    <div>
      <div className="c-center">
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Add new bank account
        </Button>
      </div>
      {!accounts || isLoading ? (
        <PageSpinner />
      ) : (
        accounts.map((account) => (
          <Accounts
            key={account.accountNumber}
            {...account}
            getBankAccounts={getBankAccounts}
            organizationId={organizationId}
          />
        ))
      )}

      {!accounts && !isLoading && (
        <Empty description="No Bank account created" />
      )}

      <CreateBankModal
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        getBankAccounts={getBankAccounts}
      />
    </div>
  );
}

function Accounts(
  props: BankAccount & {
    getBankAccounts: () => Promise<void>;
    organizationId: string;
  }
) {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  return (
    <div className={styles.table}>
      <table>
        <tbody>
          <tr>
            <th>Bank name</th>
            <td>{props.bankName}</td>
          </tr>
          <tr>
            <th>Account name</th>
            <td>{props.accountName}</td>
          </tr>
          <tr>
            <th>Account number</th>
            <td>{props.accountNumber}</td>
          </tr>
          <tr>
            <th>{props.isDefault ? "Default" : "Set as default"}</th>
            <td>
              <Checkbox
                checked={props.isDefault}
                disabled={isProcessing}
                onChange={async (e) => {
                  if (e.target.checked) {
                    try {
                      setIsProcessing(true);
                      await axios.put(`account/${props.id}/default`, {
                        organizationId: props.organizationId,
                      });
                      props.getBankAccounts();
                    } catch (error) {
                      requestErrorHandler(error);
                    } finally {
                      setIsProcessing(false);
                    }
                  }
                }}
              />
            </td>
          </tr>
        </tbody>
        <Popconfirm
          title="Sure to delete?"
          okButtonProps={{ type: "link", danger: true }}
          cancelButtonProps={{ type: "link" }}
          onConfirm={async () => {
            try {
              setIsDeleting(true);
              await axios.delete(
                `account/${props.id}/organization/${props.organizationId}`
              );
              props.getBankAccounts();
            } catch (error) {
              requestErrorHandler(error);
            } finally {
              setIsDeleting(false);
            }
          }}
        >
          <Button
            style={{ padding: 0 }}
            loading={isDeleting}
            icon={<DeleteOutlined />}
            type="link"
            danger
          >
            Delete account
          </Button>
        </Popconfirm>
      </table>
    </div>
  );
}
