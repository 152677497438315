import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  token: string | null;
  user: any;
  supplier: Supplier | null;
};

const initialState: AuthState = {
  user: null,
  token: null,
  supplier: null,
};

const slice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    addToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    signIn(state, action: PayloadAction<{ user: any; supplier: any }>) {
      state.user = action.payload.user;
      state.supplier = action.payload.supplier;
    },
    signOut(state) {
      state.token = null;
      state.user = null;
      state.supplier = null;
    },
  },
});

export default slice.reducer;
export const { addToken, signIn, signOut } = slice.actions;
