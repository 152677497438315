import { Table, Input, Space } from "antd";
import Text from "antd/lib/typography/Text";
import styles from "./quotations.module.css";
import { SearchOutlined } from "@ant-design/icons";
import DashboardShared from "components/DashboardShared/DashboardShared";
import { navigate, Router } from "@reach/router";
import QuotationsView from "./quotations";
import { useEffect, useState } from "react";
import axios from "config/axios";
import dayjs from "dayjs";
import useSearch from "hooks/useSearch";
import React from "react";

const AllQuotations = (props: ReachPathProps) => {
  const [quotations, setQuotations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSearch, isSearching, searchQuery } = useSearch();

  useEffect(() => {
    setLoading(true);
    axios.get("quotation/post").then((res) => {
      setQuotations(res.data.data.quotations);
      setLoading(false);
    });
  }, []);
  let searchResult = quotations.filter((quotation) => {
    if (!quotation) return "";
    return (
      quotation?.id?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      quotation?.builder?.toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1 ||
      quotation?.materialPrice
        ?.toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(quotation.createdAt)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1 ||
      dayjs(quotation.expirationDate)
        .format("MMM DD, YYYY")
        .indexOf(searchQuery.toLowerCase()) > -1
    );
  });

  // let searchResult;

  const webTableColumns = [
    {
      title: "QUOTATION ID",
      dataIndex: "id",
      key: "id",
      render: (text: string) => text?.substring(0, 7),
    },
    {
      title: "CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => dayjs(text).format("MMM DD, YYYY"),
    },
    {
      title: "EXPIRES",
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (text: string) => dayjs(text).format("MMM DD, YYYY"),
    },
    {
      title: "BUILDER",
      dataIndex: "builder",
      key: "builder",
      render: (text: Supplier) => text.businessName,
    },
    // {
    //   title: "STATUS",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text: string) => (
    //     <div className={styles.total}>
    //       {text === "pending" ? (
    //         <Text style={{ color: "orange" }}>{text}</Text>
    //       ) : (
    //         <Text style={{ color: "green" }}>{text}</Text>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "TOTAL PRICE",
      dataIndex: "materialPrice",
      key: "materialPrice",
      render: (text: number) => (
        <div className={styles.total}>
          {text.toLocaleString()}
          {/* <Button type="text" className={styles["more-button"]}>
            <MoreOutlined />
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles["body-topbar"]}>
        <Text> Quotations </Text>
        <Space>
          <Input
            onChange={(e) => handleSearch(e)}
            prefix={<SearchOutlined />}
          />
        </Space>
      </div>
      <div style={{ overflow: "auto" }}>
        <Table
          pagination={false}
          columns={webTableColumns}
          dataSource={isSearching ? searchResult : quotations}
          className={styles.table}
          loading={loading}
          rowClassName={styles["table-row"]}
          style={{ minWidth: "600px", textTransform: "capitalize" }}
          onRow={(row) => {
            return {
              onClick: (event) => {
                navigate(`quotation/${row.id}`);
              },
            };
          }}
        />
      </div>
    </div>
  );
};

export default function Quotations() {
  return (
    <DashboardShared title="Quotations">
      <Router>
        <AllQuotations path="/*" />
        <QuotationsView path="/:id" />
      </Router>
    </DashboardShared>
  );
}
