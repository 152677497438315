import { Table, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./request.module.css";
import classNames from "classnames";
import TopTab from "components/TopTab/TopTab";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import axios from "config/axios";
import useAuth from "hooks/useAuth";
import requestErrorHandler from "util/requestErrorHandler";
import React from "react";

const columns = [
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => <span>{dayjs(text).format("DD MM YYYY")}</span>,
  },
  {
    title: "REFERENCE",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "AMOUNT",
    dataIndex: "materialPrice",
    key: "materialPrice",
    render: (text: number) => <span>₦{text.toLocaleString()}</span>,
  },
  {
    title: "RECEPIENT",
    dataIndex: "supplier",
    key: "supplier.businessName",
    ellipsis: true,
    render: (text: Supplier) => (
      <Tooltip title={text.businessName}>{text.businessName}</Tooltip>
    ),
  },
  {
    title: "STATUS",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (text: boolean) => (text ? "Paid" : "Unpaid"),
  },
];

export default function History(props: ReachPathProps) {
  const [page] = useState(0);
  const { supplier } = useAuth();
  const [, setTotalpages] = useState(1);
  const [data, setData] = useState<PaymentsPartner[]>([]);
  const getPayments = useCallback(() => {
    axios
      .get(`paymentpartner/getpaidbySupplier/${supplier?.id}`, {
        params: { page },
      })
      .then(({ data: { data } }) => {
        setData(data.paymentPartners);
        setTotalpages(data.totalpages);
      })
      .catch((error) => {
        requestErrorHandler(error);
      });
  }, [page, supplier?.id]);
  useEffect(() => {
    getPayments();
  }, [getPayments]);
  return (
    <>
      <TopTab
        links={[
          { to: "../", name: "Pending payments" },
          { to: "", name: "History" },
        ]}
      />
      <div className={classNames(["c-end", styles.search])}>
        <div>
          <Input placeholder="search" prefix={<SearchOutlined />} />
        </div>
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table
          style={{ minWidth: "600px" }}
          rowClassName={styles["table-row"]}
          dataSource={data}
          columns={columns}
        />
      </div>
    </>
  );
}
