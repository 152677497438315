import AuthFormShared from "components/AuthFormShared/AuthFormShared";
import { Button, Form, Input, message, Row } from "antd";
// import { PhoneInput, PhoneInputFormItem } from "@validate-phone/antd-input";
import { navigate, Redirect, useLocation } from "@reach/router";

import styles from "./signIn.module.css";
import { parse } from "fast-qs";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axiosConfig from "config/axios";
import PageSpinner from "components/PageSpinner";
import requestErrorHandler from "util/requestErrorHandler";
import React from "react";
// fekneligno@vusra.com

export default function ResetPassword(props: ReachPathProps) {
  const [form] = Form.useForm();
  // const [isLoading] = useState(false);

  const [verified, setVerified] = useState<boolean>();
  // const { user } = useAuth();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>();

  const { email, token, organizationId } = parse(search) as {
    email: string;
    token: string;
    organizationId: string;
  };
  useEffect(() => {
    axiosConfig
      .post(`${process.env.REACT_APP_BACKEND_URL}/verifytoken`, {
        email,
        token,
      })
      .then((data) => {
        setVerified(true);
      })
      .catch((error) => {
        requestErrorHandler(error);
        setVerified(false);
        <Redirect from="/" to="/login" noThrow />;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log({ organizationId });

  async function handleSubmit(values: any | {}) {
    setIsLoading(true);
    axiosConfig
      .post("user/reset", {
        organizationId,
        password: values.newPassword,
      })
      .then((res) => {
        message.success("Password reset successfully");
        navigate("/login");
        setIsLoading(false);
      })
      .catch((error) => {
        requestErrorHandler(error);
      })
      .finally(() => setIsLoading(false));
  }

  if (verified === undefined)
    return (
      <div className={styles["confirm-email-wrapper"]}>
        <PageSpinner />
      </div>
    );
  if (verified === false) {
    return <Redirect from="*" to="../404" />;
  }

  // if (user) return <Redirect from="/" to="/builders/buy" noThrow />;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles["auth-form"]}>
          <Row>
            <Button type="link" onClick={() => navigate("/forgot-password")}>
              <ArrowLeftOutlined color="primary" />
            </Button>
          </Row>
          <AuthFormShared title="Reset password" {...props}>
            <div className="w-100">
              <div className={styles["proceed-circle"]} />
              <div className={styles["proceed-circle"]} />

              <Form
                scrollToFirstError
                // eslint-disable-next-line no-template-curly-in-string
                validateMessages={{ required: "'${label}' is required!" }}
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
              >
                <Form.Item
                  initialValue={email}
                  name="email"
                  label="email Address"
                  rules={[{ required: true }, { type: "email" }]}
                  hidden
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={[{ required: true, message: "Cannot be blank" }]}
                  name="newPassword"
                  label="New Password"
                  dependencies={["newPassword"]}
                  hasFeedback
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  // rules={[{ required: true, message: "Cannot be blank" }]}
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Row justify="space-between">
                  <Button
                    loading={isLoading}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                  <Button
                    size="large"
                    danger
                    onClick={() => navigate("/login")}
                  >
                    CANCEL
                  </Button>
                </Row>
              </Form>
              <p className="text-center">
                {/* Already have an account? <Link to="/login">Sign in</Link> */}
              </p>
            </div>
          </AuthFormShared>
        </div>
      </div>
    </div>
  );
}
